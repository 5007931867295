<template>
  <li>
    <div class="row_mark">
      <p>Марка</p>
      <select v-model="mId" class="selectio">
        <option disabled value="undefined">Выберите марку</option>
        <template v-for="m in marks">
          <option v-if="m.id > 0" :key="m.id" :value="m.id">
            {{ m.name }}
          </option>
        </template>
      </select>
    </div>
    <button :disabled="mId === 0" style="margin-left: 10px" @click="mId = 0">
      Нет моего автомобиля
    </button>
  </li>
</template>

<script>
export default {
  props: {
    marks: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
      default: null,
    },
  },
  computed: {
    mId: {
      get() {
        return this.value?.id;
      },
      set(val) {
        const mark = this.marks.find((m) => m.id === val);
        this.$emit("input", mark);
      },
    },
  },
};
</script>
<style scoped>
.row_mark {
  display: flex;
  align-items: center;
  width: 100%;
}
.row_mark .selectio {
  width: calc(100% - 25px) !important;
}
</style>
