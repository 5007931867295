<template>
  <li>
    <div class="sizes">
      <p>Габариты кузова, м</p>
      <Variant
        :value="vcq.variant"
        :size="vcq.size"
        :variants="variants"
        class="length_hight"
        @input="setVCQ('variant', $event)"
        @update:size="setVCQ('size', $event)"
      />
    </div>
    <div class="color_count">
      <Color :value="vcq.color" @input="setVCQ('color', $event)" />
      <Quantity :value="vcq.quantity" @input="setVCQ('quantity', $event)" />
    </div>
  </li>
</template>

<script>
import Variant from "./variant";
import Color from "./color";
import Quantity from "./quantity";

export default {
  components: { Variant, Color, Quantity },
  props: {
    variants: {
      type: Array,
      default: null,
    },
    value: {
      type: Object,
      default: null,
    },
  },
  computed: {
    vcq() {
      return this.value;
    },
  },
  methods: {
    setVCQ(key, val) {
      this.$emit("input", { ...this.value, [key]: val });
    },
  },
};
</script>
<style scoped>
.color_count {
  display: flex;
  flex-direction: row;
  margin-left: auto;
}
</style>
