<template>
  <div>
    <ul class="faq-body">
      <li
        v-for="(c, indx) in cars"
        :key="indx"
        :class="{ active: indx == carIndx }"
      >
        <a class="car" @click="carIndx = indx">
          <img
            v-if="c.vcq.variant && c.vcq.variant.preview"
            :src="c.vcq.variant && c.vcq.variant.preview"
            :style="`background: #${c.vcq.color}`"
          />
          <img v-else :src="settings.previewEmpty" />
          <p>x {{ c.vcq.quantity }}</p>
        </a>
        <a @click="removeCarRow(indx)"><i class="fas fa-trash-alt"></i></a>
      </li>
    </ul>
    <button v-if="cars.length < 7" @click="addCarRow">
      <i class="fal fa-plus"></i>
    </button>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import { mapFields, mapMultiRowFields } from "vuex-map-fields";

export default {
  computed: {
    ...mapMultiRowFields(["cars"]),
    ...mapFields(["settings", "carIndx"]),
  },
  methods: {
    ...mapMutations(["addCarRow", "removeCarRow"]),
  },
};
</script>
