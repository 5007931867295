<template>
  <ul>
    <SelectMark v-model="mark" :marks="marks" />
    <SelectMarkIcon v-model="mark" :marks="marks" class="icon" />
    <SelectCarProp
      v-model="model"
      :items="mark && mark.models"
      title="Модель"
      placeholder="Для возможности выбора модели, необходимо выбрать марку"
      :type="mark && mark.id === 0 ? 'images' : 'select'"
      :class="{ 'selectioText': !(mark && mark.id === 0) }"
    />
    <SelectCarProp
      v-model="overallDimension"
      :items="model && model.overallDimensions"
      title="Тип"
      placeholder="Для выбора параметра тип, необходимо указать марку и модель"
      class="selectioText"
    />
    <SelectVariantColorQuantity
      v-model="vcq"
      :variants="overallDimension && overallDimension.variants"
      class="width_hight"
    />
  </ul>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { mapFields, createHelpers } from "vuex-map-fields";

import SelectMark from "./select-mark";
import SelectMarkIcon from "./select-mark-icon";
import SelectCarProp from "./select-car-prop";
import SelectVariantColorQuantity from "./select-variant-color-quantity/";

const { mapFields: mapCarFields } = createHelpers({
  getterType: "getCarField",
  mutationType: "updateCarField",
});

export default {
  components: {
    SelectMark,
    SelectMarkIcon,
    SelectCarProp,
    SelectVariantColorQuantity,
  },
  computed: {
    ...mapCarFields(["mark", "model", "overallDimension", "vcq"]),
    ...mapFields(["settings", "carIndx"]),
    ...mapGetters(["marks", "totalTypes", "totalCars"]),
  },
  watch: {
    carIndx() {
      this.switchedCar = true;
    },
    mark(val, prev) {
      if (!this.switchedCar && val != prev) {
        this.model = val?.models?.length === 1 ? val.models[0] : null;
      }
    },
    model(val, prev) {
      if (!this.switchedCar && val != prev) {
        this.overallDimension =
          val?.overallDimensions?.length === 1
            ? val.overallDimensions[0]
            : null;
      }
    },
    overallDimension(val, prev) {
      if (!this.switchedCar && val != prev) {
        this.vcq.variant =
          val?.vcq?.variants?.length === 1 ? val.vcq.variants[0] : null;
      }
      this.switchedCar = false;
    },
  },
  created() {
    this.featchMarks();
  },
  methods: {
    ...mapMutations(["pushColor", "nextCar", "prevCar"]),
    ...mapActions(["featchMarks"]),
  },
};
</script>
