<template>
  <div class="brand_selection">
    <Top
      :state="{ value: 1, text: 'Автомобиль' }"
    />
    <Brand class="brand" />
    <Bottom
      :next="{ text: 'Забрендировать', name: 'constructor-car' }"
      :disabled="!isIssetCar"
      class="bottom"
      @click="onClick"
    />
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import config from "@/config";

import Top from "../__top__";
import Bottom from "../__bottom__";
import Brand from "./__brand__";
import { mapFields } from "vuex-map-fields";

export default {
  baseURL: config.BASE_URL,
  components: {
    Top,
    Brand,
    Bottom,
  },
  computed: {
    ...mapFields(["design"]),
    ...mapGetters(["isIssetCar"]),
  },
  created() {
    this.featchTempCars();
  },
  methods: {
    ...mapActions(["featchTempCars"]),
    ...mapMutations(["clearCars"]),
    onClick() {
      this.clearCars();
      this.design = "construct";
    },
  },
};
</script>
